import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  CData,
  Constant,
  RequiredServiceQualElement,
  Services,
} from "../../../interfaces/home";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { GenerateGroupInvoiceService } from "../../../services/generate-group-invoice.service";

@Component({
  selector: "app-group-invoice-form",
  templateUrl: "./group-invoice-form.component.html",
  styleUrls: ["./group-invoice-form.component.scss"],
  providers: [Constant, DialogService],
})
export class GroupInvoiceFormComponent implements OnInit {
  public invoiceGroupForm: FormGroup;
  paymentCycles: CData[];
  invoicingIntervals: CData[];
  selectedService: Services | null;
  serviceRequiredFields: RequiredServiceQualElement[];
  today: Date;
  minStartDate: Date;
  maxStartDate: Date;
  maxEndDate: Date;
  minEndDate: Date;
  minFulfilmentStartDate: Date;
  minFulfilmentEndDate: Date;
  maxFulfilmentStartDate: Date;
  maxFulfilmentEndDate: Date;
  minActivationDate: Date;
  maxActivationDate: Date;
  minInvoiceDate: Date;
  maxInvoiceDate: Date;
  public loadingPage: boolean;
  saveButtonLabel: string;
  isSubmitted: boolean = false;
  firstSendDateRangeError: string;
  isOneOffCycle: boolean = false;

  constructor(
    public groupInvoiceRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    public constant: Constant
  ) {
    this.loadingPage = true;
  }

  ngOnInit(): void {
    this.today = new Date();
    this.today.setMonth(this.today.getMonth() - 3);
    this.paymentCycles = this.constant.paymentCycles;
    this.invoicingIntervals = this.constant.invoicingIntervals;

    this.invoiceGroupForm = this.formBuilder.group({
      fulfillmentStartDate: [this.today, Validators.required],
      fulfillmentEndDate: ["", Validators.required],
      contractStartDate: ["", Validators.required],
      contractEndDate: ["", Validators.required],
      activationDate: ["", Validators.required],
      firstInvoiceSendDate: ["", Validators.required],
      paymentCycle: ["", Validators.required],
      invoicingInterval: ["", Validators.required],
    });

    if (!this.config.data.isNewGroupInfo) {
      const _groupData = this.config.data.invoiceGroupData;

      if (!_groupData?.contractStartDate) {
        this.saveButtonLabel = "Create";
      } else {
        this.saveButtonLabel = "Update";
        if (_groupData) {
          this.invoiceGroupForm.patchValue({
            fulfillmentStartDate: new Date(_groupData.fulfillmentStartDate),
            fulfillmentEndDate: new Date(_groupData.fulfillmentEndDate),
            contractStartDate: new Date(_groupData.contractStartDate),
            contractEndDate: new Date(_groupData.contractEndDate),
            activationDate: new Date(_groupData.activationDate),
            firstInvoiceSendDate: new Date(_groupData.firstInvoiceSendDate),
            paymentCycle: this.paymentCycles.find(
              (x) => x.id == _groupData.paymentCycle
            ),
            invoicingInterval: this.invoicingIntervals.find(
              (x) => x.id == _groupData.invoicingInterval
            ),
          });
        }
      }
    } else {
      this.saveButtonLabel = "Create";
    }
    //set the mins and maxs of dates
    this.verifyDate();
  }

  closeGroupInvoice(data?: any) {
    this.groupInvoiceRef.close(data);
  }

  private rangeDate(intervalId: number) {
    switch (intervalId) {
      case 0:
        return 30;
      case 1:
        return 60;
      case 2:
        return 90;
      case 3:
        return 365;
      case 4:
        return 180;
      default:
        return 30;
    }
  }

  private validateInvoiceSendingDateWithinInterval(intervalId: number) {
    //get dates
    var days = this.rangeDate(intervalId);
    //from date contract start date
    const serviceValue = this.invoiceGroupForm.value;
    const fromDate = new Date(serviceValue.contractStartDate);
    const invoiceDate = new Date(serviceValue.firstInvoiceSendDate);
    const toDate = new Date(fromDate);
    toDate.setDate(fromDate.getDate() + days);

    let isTrue =
      invoiceDate.getTime() <= toDate.getTime() &&
      invoiceDate.getTime() >= fromDate.getTime();
    return isTrue;
  }

  verifyDate() {
    const serviceValue = this.invoiceGroupForm.value;
    const fulfillmentStartDate = new Date(serviceValue.fulfillmentStartDate);
    const fulfillmentEndDate = new Date(serviceValue.fulfillmentEndDate);
    const contractStart = new Date(serviceValue.contractStartDate);
    const contractEnd = new Date(serviceValue.contractEndDate);
    // const activationDate = new Date(serviceValue.activationDate);
    // const invoiceDate = new Date(serviceValue.firstInvoiceSendDate);

    this.minFulfilmentStartDate = this.today;

    // this.maxFulfilmentStartDate: Date;
    this.minFulfilmentEndDate = new Date(fulfillmentStartDate);
    this.minFulfilmentEndDate.setDate(fulfillmentStartDate.getDate() + 1);

    // this.maxFulfilmentEndDate: Date;
    this.minStartDate = fulfillmentEndDate;
    // this.maxStartDate = ;
    this.minEndDate = new Date();
    this.minEndDate = contractStart;
    // this.maxEndDate= Date;
    this.minActivationDate = new Date();
    this.minActivationDate = fulfillmentEndDate;
    this.maxActivationDate = new Date();
    this.maxActivationDate = contractStart;
    this.minInvoiceDate = new Date();
    this.minInvoiceDate = contractStart;
    this.maxInvoiceDate = new Date();
    this.maxInvoiceDate = contractEnd;

    //fullfil-start-error

    if (fulfillmentStartDate) {
      if (fulfillmentEndDate < fulfillmentStartDate) {
        this.invoiceGroupForm
          .get("fulfillmentStartDate")
          .setErrors({ "fullfill-start-error": true });
      } else {
        this.invoiceGroupForm.get("fulfillmentStartDate").setErrors(null);
      }

      if (contractStart > contractEnd) {
        this.invoiceGroupForm
          .get("contractStartDate")
          .setErrors({ "contract-start-error": true });
      } else {
        this.invoiceGroupForm.get("contractStartDate").setErrors(null);
      }
    }
  }

  checkInvoicingCycle() {
    const serviceValue = this.invoiceGroupForm.value;
    var startDate = serviceValue.contractStartDate;

    if (!startDate) {
      return;
    }

    const date = new Date(startDate);
    var id = this.invoiceGroupForm.controls.invoicingInterval?.value?.id;
    if (id == 5) {
      this.isOneOffCycle = true;
      this.invoiceGroupForm.patchValue({
        activationDate: date,
        contractEndDate: date,
        firstInvoiceSendDate: date,
      });
    } else {
      this.isOneOffCycle = false;
    }
  }

  continue() {
    this.isSubmitted = true;
    var interval = this.invoiceGroupForm.value?.invoicingInterval;

    if (this.validateInvoiceSendingDateWithinInterval(interval?.id)) {
      this.invoiceGroupForm.get("firstInvoiceSendDate").setErrors(null);
    } else {
      this.invoiceGroupForm
        .get("firstInvoiceSendDate")
        .setErrors({ "send-date-range": true });
      this.firstSendDateRangeError = `First invoice sending date is not within initial ${interval?.value?.toLowerCase()} of contract`;
    }

    if (this.invoiceGroupForm.invalid) {
      return;
    }

    this.invoiceGroupForm.patchValue({
      paymentCycle: this.invoiceGroupForm.value?.paymentCycle?.id,
      invoicingInterval: this.invoiceGroupForm.value?.invoicingInterval?.id,
    });

    this.closeGroupInvoice(this.invoiceGroupForm.value);
  }
}
